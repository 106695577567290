.App {
  text-align: center;
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
  
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Allow the children to wrap to the next line */
  padding: 10px; /* Add some padding */
  box-sizing: border-box; /* Ensure padding does not affect the width */
}

.logo {
  font-size: 24px;
  padding-left: 1%;
}

/* Ensure the Stack component is responsive */
.menu {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap */
  justify-content: center; /* Center buttons horizontally */
}

.menu .MuiButton-root {
  margin: 5px; /* Add some margin around each button */
  flex: 1; /* Allow buttons to grow and shrink equally */
  min-width: 80px; /* Ensure buttons have a minimum width */
}

/* New styles for responsiveness */
@media (max-width: 768px) {
  .top-bar {
    justify-content: center; /* Center items horizontally */
  }

  .logo {
    padding-bottom: 10px; /* Add some spacing below the logo */
  }
}


.menu button {
  color: white;
}

.menu button:hover {
  color: #61dafb;
}

.content-area {
  flex: 1;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  background-color: #f0f0f0; /* Light background */
}
.upload-games-container {
  text-align: center;
  width: 100%;
  background-color: #f0f0f0; /* Match the page's background color */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* Upload games form */
.upload-games-form {
  width: 75%;
  max-width: 600px;
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  justify-content: center;
}

/* Form row */
.form-row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-submit {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-winning-method {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-row label {
  margin-right: 10px;
  font-weight: bold;
}

.name-selection{
  display: flex;
  flex-direction: row;

}


.form-row input,
.form-row select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-row input[type='date'] {
  appearance: none;
}

/* Team container */
.team-container {
  display: flex;
  justify-content: space-between;
  
  margin-bottom: 20px;
}

/* Team section */
.team-one,
.team-two {
  flex-basis: 48%;
}

.game-end{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Player names */
.player-names {
  margin-bottom: 20px;
}

/* Title */
h2 {
  margin-bottom: 20px;
  color: #333;
}

/* Checkbox */
.checkbox-label {
  display: inline-block;
  margin-right: 10px;
}

.checkbox-label input {
  margin-right: 5px;
}


/* Upload games form */
.table-box{
  width: 100%;
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  margin: 0 auto; /* Center the form horizontally */
}

.table-button-group {
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  padding:2%;
  gap: 10px
}

.elo-history-container {
  padding: 20px;
}

.elo-history-heading {
  text-align: center;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.nav-button {
  margin: 0 10px;
}

.chart-container {
  width: 85%;
 
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  justify-content: center;
}







.analytics-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust as needed */
}





.card {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Space elements evenly */
}

.big-bold-number {
  font-size: 48px;
  font-weight: bold;
  color: #333;
}

.card-text {
  font-weight: bold;
  color: #333;
  font-size: 18px;
}

.sub-value-text,
.sub-value-two-text {
  
  color: #333;
  font-size: 14px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Adjust the gap between game cards */
  color: black; /* Set text color to black */
  padding: 20px;
}

.game-card-container {
  background-color: white;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for a card effect */
  position: relative; /* Add position relative for absolute positioning */
  width: 90%; /* Adjust the width of the game card */
  margin: 20px auto; /* Center the card and add some space around it */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 20px; /* Add padding inside the card */
}
/* Style for game ID */
.uuid {
  color: black; /* Set color to black */
}

.id-delete-container {
  position: absolute; /* Position the container absolutely */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  padding: 10px; /* Add padding to the container */
}

.delete-btn {
  background-color: transparent;
  color: red;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.game-details table {
  width: 100%;
  min-width: 1200px; /* Set a minimum width to ensure content overflows */
  border-collapse: collapse;
  padding-top: 10px;
}

/* Style for game details table */
.game-details table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px; /* Adjust font size */
}

.game-details th, .game-details td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Left align player name columns */
.player-names {
  text-align: left;
}
.player-master-container {
  width: 100%;
  background-color: #f0f0f0; /* Match the page's background color */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.player-stats-first-container {
  width: 95%;
  padding: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Optional: ensure charts are evenly spaced */
}
.player-master-container {
  width: 100%;
  background-color: #f0f0f0; /* Match the page's background color */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.player-stats-first-container {
  width: 95%;
  padding: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Optional: ensure charts are evenly spaced */
}

.player-stats-elo-container {
  width: 48%; /* Adjust width to ensure two charts fit side by side */
  background-color: #f0f0f0; /* White background */
  margin-right: 2%; /* Add margin to the right */
  
  padding: 10px; /* Add padding for inner elements */
  display: flex;
  flex-direction: column; /* Ensure children are stacked vertically */
}

.player-stats-boxes-container {
  flex: 1; /* Make the container take up the available space */
  display: flex;
  flex-direction: column; /* Stack rows vertically */
}

.player-stats-container-row {
  background-color: #f0f0f0; /* Background color */
  margin-bottom: 10px; /* Space between rows */
  flex: 1; /* Make each row take up 50% of the container height */
  display: flex; /* Enable flex layout for the row */
  justify-content: space-between; /* Spread out cards evenly */
}

/* Remove margin-bottom for the last row to avoid extra space at the end */
.player-stats-container-row:last-child {
  margin-bottom: 0;
}

.card-stats {
  position: relative;
  height: 100%;
  width: 30%; /* Adjust width to fit three cards in a row */
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center content */
  align-items: center; /* Center content */
  
}

.player-master-container {
  padding: 20px;
}

.player-stats-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player-stats-elo-container {
  width: 100%;
}
